<template>
  <div>
    <div id="banner-img">
      <!-- MENU -->
      <b-container id="menu" fluid>
        <div class="m-0">
          <div class="d-flex align-items-center">
              <b-container class="inside" fluid>
                  <b-row class="justify-content-between align-items-center text-wrapper">
                      <b-col class="col-sm-6" >
                        <a :class="{'home': 'home' == this.$route.name}" :href="$router.resolve({ name: 'home'}).href"><img src="../assets/img/Logo White.svg"></a>
                      </b-col>
                      <b-col class="col-sm-6 justify-content-end" >
                        <a href="#contact-us"><div class="centerv text-right">
                          <a href="#contact-us"><p class="my-0">CONTACT US</p></a>
                        </div></a>
                      </b-col>
                  </b-row>
              </b-container>
          </div>
        </div>
      </b-container>
      <!-- BANNER -->
      <b-container id="banner" fluid>
        <b-row class="text-wrapper">
          <b-col class="col-sm-8">
            <h2>Energy <br class="h"> consulting <br class="h"> services </h2>
          </b-col>
        </b-row>
        <b-row class="justify-content-between ps-5 paragraph">
          <b-col class="col-sm-6 ps-5 ms-4 margin">
            <p>As experts we understand that energy efficiency –reducing energy demand – is the cheapest and most effective means to meet reduce carbon emissions.</p>
          </b-col>
          <b-col class="col-sm-4 justify-content-end h" id="img-banner">
            <img src="../assets/img/img_banner_small.png">
          </b-col>
        </b-row>
        <b-row class="get-started">
          <b-col class="col-sm-7 button">
            <a href="#contact-us"><p class="my-0">GET STARTED <img src="../assets/img/icn_arrow.svg"></p></a>
          </b-col>
        </b-row>
      </b-container>
    </div>
      <!--WHAT & HOW-->
    <b-container id="banner" fluid>
        <b-row class="text-wrapper">
          <b-col class="col-sm-9">
            <h1>The cheapest energy is the one we do not use. <img src="../assets/img/icn_star_small.svg"></h1>
          </b-col>
        </b-row>
        <b-row class="highlight-img">
          <b-col class="col-sm-12 justify-content-center">
             <img src="../assets/img/img_solarpanels.png">
          </b-col>
        </b-row>
        <b-row class="text-wrapper">
          <b-col class="col-sm-11 justify-content-start">
            <h3 class="pb-5">Our capabilities include</h3>
          </b-col>
        </b-row>
        <b-row class="text-wrapper grid">
          <b-col class="col-sm-2 capabilities">
            <img src="../assets/img/icn_star_small.svg">
            <b-row>
              <p>Find options and solutions to reduce the overall cost of energy through different methods.</p>
            </b-row>
          </b-col>
          <b-col class="col-sm-2 capabilities">
            <img src="../assets/img/icn_star_small.svg">
            <b-row>
              <p>⁠Design and implement programs to reduce energy consumption.</p>
            </b-row>
          </b-col>
          <b-col class="col-sm-2 capabilities">
            <img src="../assets/img/icn_star_small.svg">
            <b-row>
              <p>Introduce and train key employees to these programs.</p>
            </b-row>
          </b-col>
          <b-col class="col-sm-2 capabilities">
            <img src="../assets/img/icn_star_small.svg">
            <b-row>
              <p>Monitor energy programs to ensure they're successful.</p>
            </b-row>
          </b-col>
        </b-row>


    </b-container>
    <!--WHAT WE OFFER-->
    <b-container id="offer" fluid>
        <b-row class="text">
          <b-col class="col-sm-9">
            <h3 class="pb-5">What we offer</h3>
          </b-col>
        </b-row >
            <b-row class="text offersrow">
                <b-col class="offer-wrapper" md="3">
                    <img src="../assets/img/icn_capabilities1.svg">
                    <h4>Study</h4>
                    <p>Analysis. Implementation. Monitoring</p>
                </b-col>
                <b-col class="offer-wrapper" md="3">
                  <img src="../assets/img/icn_capabilities2.svg">
                    <h4>Project<br>Management</h4>
                    <p></p>
                </b-col>
                <b-col class="offer-wrapper " md="3">
                  <img src="../assets/img/icn_capabilities3.svg">
                    <h4 class="especial">Interconnection Support</h4>
                    <p>Path to interconnection. Budget/timing forecast.</p>
                </b-col>
                <b-col class="offer-wrapper" md="3">
                    <img src="../assets/img/icn_capabilities4.svg">
                    <h4>Regulatory <br>Support</h4>
                    <p>Tariffs</p>
                </b-col>
                <b-col class="offer-wrapper" md="3">
                    <img src="../assets/img/icn_capabilities5.svg">
                    <h4>Technical <br>contract advisory</h4>
                    <p></p>
                </b-col>
                <b-col class="offer-wrapper" md="3">
                    <img src="../assets/img/icn_capabilities6.svg">
                    <h4>RFP Third-party consultant</h4>
                    <p></p>
                </b-col>
                <b-col class="offer-wrapper" md="3">
                    <img src="../assets/img/icn_capabilities7.svg">
                    <h4>RFP manager</h4>
                    <p></p>
                </b-col>
                <b-col class="offer-wrapper" md="3">
                    <img src="../assets/img/icn_capabilities8.svg">
                    <h4>Site evaluation</h4>
                    <p></p>
                </b-col>
            </b-row>  
    </b-container>
    <!-- CONTACT US SECTION -->
    <b-container id="contact-us" fluid>
      <img id="vector" src="../assets/img/icn_star_contact.svg">
      <b-row class="text">
          <b-col class="col-sm-7 pt-5">
            <h2 class="pt-5">Contact us now to get started! </h2>
          </b-col>
      </b-row >
      <!-- FORM -->
      <b-row class="text-contact">
        <b-col class="col-sm-8">
          <b-form>
            <b-row>
                <b-form-group id="input-group-1">
                  <div style="display: none;">
                    <b-form-input
                      id="honeypot"
                      v-model="honeypot"
                      placeholder=" "
                    ></b-form-input>
                  </div>
                </b-form-group>
              <b-col class="col-sm-6">
                <b-form-group id="input-group-1">
                  <div class="form-floating media name align-items-center">  
                      <b-form-input
                        id="input-1"
                        v-model="name"
                        placeholder=" "
                        required
                      ></b-form-input>
                      <label for="floatingInput">Name *</label>
                  </div>
                </b-form-group>
              </b-col>
              <b-col class="col-sm-6">
                <b-form-group id="input-group-2">
                  <div class="form-floating media">  
                    <b-form-input
                      id="input-2"
                      v-model="email"
                      type="email"
                      placeholder=" "
                      required
                    ></b-form-input>
                    <label for="floatingInput">E-mail *</label>
                  </div>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col class="col-sm-6">
                <b-form-group id="input-group-3">
                  <div class="form-floating media">  
                    <b-form-input
                      id="input-3"
                      v-model="phone"
                      placeholder=" "
                      required
                    ></b-form-input>
                    <label for="floatingInput">Phone number *</label>
                  </div>
                </b-form-group>
              </b-col>
              <b-col class="col-sm-6">
                <b-form-group id="input-group-4">
                  <div class="form-floating media">
                    <b-form-input
                    id="input-4"
                    v-model="company"
                    placeholder=" "
                    ></b-form-input>
                    <label for="floatingInput">Company</label>
                  </div>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col class="col-sm-12">
                <b-form-group id="input-group-5">
                  <div class="form-floating texta">
                    <b-form-textarea 
                      id="input-5"
                      v-model="message"
                      placeholder=" "
                      rows=2
                      max-rows=4
                    ></b-form-textarea>
                    <label for="input-5">Message</label>
                  </div>
                </b-form-group>
              </b-col>
            </b-row>
            <form action="" method="POST">
              <!--<div class="g-recaptcha pb-5" data-sitekey="6LeC6J0pAAAAALOZC3yJCQMf4q-nexgOhHIOoVPn" data-callback="onRecaptchaSuccess"></div>-->
                <b-row class="get-started">
                  <b-col class="col-sm-12 button button2">
                    <b-button variant="primary" class="my-0" style="color:white"  v-on:click="sendMessage">SEND <img src="../assets/img/icn_arrow.svg"></b-button>
                  </b-col>
                </b-row>
            </form>
          </b-form>          
        </b-col>
        <b-col class="pt-5 ps-5 direcciones">
          <b-row class="pb-5 pt-1">
          </b-row>
          <b-row class="pt-5 ps-5 pb-4">
            <p>
              <a href="">Spark & Bloom LLC</a>
              <br>
              <a href = "mailto:consultant@spark-bloom.com">consultant@spark-bloom.com </a>
            </p>
          </b-row>
          <b-row class="ps-5 privacy-policy">
            <a :class="{'privacy-policy': 'privacypolicy' == this.$route.name}" :href="$router.resolve({ name: 'privacypolicy'}).href"><p>Privacy Policy </p></a>
          </b-row>
        </b-col>
      </b-row>
    </b-container>
    <b-modal id="thank-you-modal" hide-footer centered>
      <template #modal-header>
        <h4 class="modal-title">
          <span>Thank you for your message!</span>
        </h4>
      </template>
      <div class="text-center">
        <b-icon icon="check-circle" variant="success" font-scale="4"></b-icon>
      </div>
      <b-button variant="success" block @click="hideModal">Close</b-button>
    </b-modal>
    <b-modal id="error-modal" hide-footer centered>
      <template #modal-header>
        <h4 class="modal-title">
          <span>Error</span>
        </h4>
      </template>
      <div class="text-center">
        <b-icon icon="x-square" variant="fail" font-scale="4"></b-icon>
      </div>
      <b-button variant="fail" block @click="hideModal">Close</b-button>
    </b-modal>
    <b-modal id="informacion-modal" hide-footer centered>
      <template #modal-header>
        <h4 class="modal-title">
          <span>Incomplete Form</span>
        </h4>
      </template>
      <div class="text-center">
        <b-icon icon="exclamation-circle" variant="info" font-scale="4"></b-icon>
      </div>
      <b-button variant="info" block @click="hideModal">Close</b-button>
    </b-modal>
  </div>
</template>

<script>
import axios from 'axios'
import { BModal } from 'bootstrap-vue';
  export default {
      components: {
      BModal
    },
    data() {
      return {
          name: '',
          email: '',
          phone: '',
          company: '',
          message: '',
          messageSend:'',
          //recaptchaResponse: '',
          //recaptchaToken: '',
      }
    },
    methods: {
  //onRecaptchaSuccess(token) {
  //  console.log("reCAPTCHA verified", token);
  //  this.recaptchaToken = token;
 // },
 hideModal() {
      this.$bvModal.hide('thank-you-modal');
      this.$bvModal.hide('error-modal');
      this.$bvModal.hide('informacion-modal');
    },
  async sendMessage() {
    if (this.honeypot) {
      console.log("Honeypot triggered.");
      return;
    }

    if (this.name !== '' && this.email !== '' && this.phone !== '') {
      try {
        const response = await axios.post('phpmailer.php', {
          name: this.name,
          email: this.email,
          phone: this.phone,
          company: this.company,
          message: this.message,
          //recaptchaResponse: this.recaptchaToken,
        });

        console.log(response.data); // Logging the response data

        if (response.data == 200 || response.data == '200') {
          this.$bvModal.show('thank-you-modal');
        } else {
          this.$bvModal.show('error-modal');
        }
      } catch (error) {
        this.$bvModal.show('error-modal');
      }
    } else {
      this.$bvModal.show('informacion-modal');
    }
  }
},
  //  mounted() {
  //    window.onRecaptchaSuccess = this.onRecaptchaSuccess;
  //  },
  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

#thank-you-modal h4, #thank-you-modal button, #thank-you-modal, #error-modal button, #informacion-modal button, #informacion-modal, #error-modal{
  font-family:"Bai Jamjuree", sans-serif;
}

.btn-info{
  background-color: transparent;
  border-style: none;
}

.btn-info:hover{
  background-color:transparent;
  border-style: none;
}


#thank-you-modal .modal-title span {
  color: #5cb85c; 
  font-family:"Bai Jamjuree", sans-serif;
}
#error-modal .modal-title span {
  color: #a51414; 
  font-family:"Bai Jamjuree", sans-serif;
}
#informacion-modal .modal-title span {
  color: #a51414; 
  font-family:"Bai Jamjuree", sans-serif;
}






body, *{
  -webkit-tap-highlight-color: transparent;
}

.container-fluid{
  padding-left: 0 !important;
  padding-right: 0 !important;
}

*{
  text-decoration: none;
}

#banner .text-wrapper, #banner .get-started, #banner .highlight-img, #offer .text, #menu .text-wrapper, #banner .paragraph{
    max-width: 83%;
    margin: auto;
}
/* MENU */

#menu{
  padding-top: 4%;
  z-index: 10;
  padding-left: 0 !important;
  padding-right: 0 !important;
}

#menu .row {
  display: flex;
}
.inside .centerv p{
  font-size: 20px;
  line-height: 25px;
  font-weight: 400;
  color:#0b0a46;
  font-family:"Bai Jamjuree", sans-serif;;
}

.inside img{
  width: 306px;
}


/* BANNER */

#banner {
    text-align: left;
    padding-top: 7%;
    padding-left: 0 !important;
    padding-right: 0 !important;
    height: auto;
    width: auto;
    z-index: 5;
}

.row.get-started{
  padding-bottom: 16%;
}

#banner-img{
  background-image: url("../assets/img/img_banner_background.png");
  height: auto;
  background-size: cover;
  position: relative;
}

#menu .col{
  display: flex;
}

.text-right {
    text-align: center;
    font-family: "Bai Jamjuree", sans-serif;
    display: flex;
    justify-content: center;
    border: 1px solid #fff;
    border-radius: 100px;
    width: 277px;
    height: 72px;
    align-items: center;
    background-color: #FFFFFF;
}

.inside .centerv p {
    font-size: 20px;
    line-height: 25px;
    font-weight: 400;
    color: #0b0a46;
    font-family: "Bai Jamjuree", sans-serif;
}

#img-banner{
  margin-top: -1%;
}



#banner .row,
#banner .col{
  display: flex;
  

}

.button img{
  width: 52px;
  height: 52px;
  position: relative;
  right: -15px;
}


#banner h2, #contact-us h2{
  font-size: 60px;
  line-height: 70px;
  font-weight: 400;
  color:#FFFFFF;
  padding-bottom: 7%;
}
.paragraph{
  height:160px;
}
#banner h1{
  font-size: 80px;
  line-height: 100px;
  font-weight: 400;
  color:#010B2E;
  padding-bottom: 13%;
}

#banner h3, #offer h3{
  font-size: 40px;
  line-height: 70px;
  font-weight: 400;
  padding-top: 10%;
  padding-bottom:9%;
}
.get-started button{
    text-align: left;
    font-family: Bai Jamjuree, sans-serif;
    display: flex;
    justify-content: space-around;
    border-radius: 100px;
    width: 277px;
    height: 74px;
    align-items: center;
    background-color: #ffffff33;
    padding: 0 !important;
    font-size: 18px !important;
    font-weight: 500;
    border-style: none;
}
.text-right{
    text-align: center;
    font-family:"Bai Jamjuree", sans-serif;;
    display: flex;
    justify-content: center;
    border: 1px solid #fff;
    border-radius: 100px;
    width:277px;
    height: 72px;
    align-items: center;
    background-color: #FFFFFF;
}

.get-started p{
    text-align: left;
    font-family:"Bai Jamjuree", sans-serif;;
    display: flex;
    justify-content: space-around;
    border-radius: 100px;
    width:277px;
    height: 74px;
    align-items: center;
    background-color: #FFFFFF33;
    padding: 0 !important;
    font-size: 18px !important;
    font-weight: 500;
    
}
#banner p, #banner .paragraph p{
  font-size: 20px;
  line-height: 25px;
  font-weight: 400;
  color:#FFFFFF;
  font-family:"Bai Jamjuree", sans-serif;;
  padding-bottom: 10%;
}
/* WHAT & HOW */

#banner hr{
  border: 1px solid #000000;
  width: 1208px;
}

#banner .text-wrapper p{
  font-weight: 400;
  color:#000000;
  line-height: 25px;
  font-size: 20px;
  font-family:"Bai Jamjuree", sans-serif;
  margin-top: 6px;
}

#banner .capabilities{
  flex-direction: column;
}

#banner .capabilities img{
  width: 31px;
  height: 31px;
}

.capabilities{
  background-color: #3924D70D;
  margin-right: 10px;
  border-radius: 20px;
  height: 240px;
  padding-top: 3%;
  padding-left: 33px;
  padding-right: 33px;
  margin-bottom: 30px;
  width: 285px;
}

#banner .grid{
  justify-content: space-evenly;
}
/* WHAT WE OFFER */

.offer-wrapper{
  margin-bottom: 60px;
}

#offer{
  padding-bottom: 3%;
}

.offer-wrapper p{
  font-family: "Kanit", sans-serif;
  font-size: 20px;
  line-height: 29.9px;
  font-weight: 300;
  font-style: italic;
  color:#010C2D;
  margin-top: 20px;
}

.offer-wrapper h4{
  font-family:"Bai Jamjuree", sans-serif;
  font-size: 25px;
  line-height: 31.25px;
  font-weight: 500;
  color:#010C2D;
  margin-top: 20px;
}

/* CONTACT US */

  #contact-us{
    background-color: #3924D7;
    position: relative;
    z-index: 5;
  }

  #vector{
    position:absolute;
    top:10%;
    right: 15%;
    width: 188px;
    height: 188px;
  }

  .text-contact .row{
    padding-bottom: 40px;
  }

  .direcciones a{
    color:#FFFFFF;
    font-size: 18px;
    font-family: "Kanit", sans-serif;
    font-weight: 300;
    line-height: 26.91px;
  }

  #contact-us .text, #contact-us .text-contact{
  max-width: 75%;
  margin:auto;
  display: flex;
  flex-direction: row;
}

.button2 img{
  width: 52px;
  height: 52px;
  position: relative;
  right: -30px;
}

.capabilities img{
  width: 55px;
  height: 55px;
}
.highlight-img img{
  object-fit: contain;
  overflow: auto;
}
/* FORM */ 
  ::placeholder{
    color: #FFFFFF !important;
  }

  textarea{
      background: transparent;
  }

  .privacy-policy p{
    color:#FFFFFF80 !important;
    font-size: 18px;
    font-family: "Kanit", sans-serif;
    font-weight: 300;
  }

  .form-control{
    background-color: transparent !important;
    overflow-y: hidden !important;
    color: #FFFFFF !important;
    font-family:"Bai Jamjuree", sans-serif !important;
    line-height: 22.5px;
    font-size: 18px;
    font-weight: 500;
  }

  .form-control:focus{
    background-color: transparent !important;
  }


  .form-floating{
    position: relative;
    display: flex;
    align-items: center;
    align-content: center;
    font-family:"Bai Jamjuree", sans-serif;
    line-height: 22.5px;
    font-size: 18px;
    font-weight: 300;
    font-size: 14px;
  }
  .form-floating > .form-control{
    height: 50px;
    position: relative;
    z-index: 1;
    padding-left: 10px;
    padding-bottom: 25px !important;
    border-radius: 10px;
    background-color: transparent !important;
    border-color: #FFFFFF;
  }

  .form-floating > label {
    position: absolute;
    font-family:"Bai Jamjuree", sans-serif;
    line-height: 22.5px;
    font-size: 18px;
    font-weight: 300;
    font-size: 14px;
    z-index: 10;
    color: #FFFFFF;
  }

  .form-floating > .form-control:focus ~ label::after, .form-floating > .form-control:not(:placeholder-shown) ~ label::after, .form-floating > .form-control-plaintext ~ label::after, .form-floating > .form-select ~ label::after{
      background: transparent;
      color: #FFFFFF80;
      border-color: #FFFFFF;
  }

  .form-floating > .form-control:focus ~ label, .form-floating > .form-control:not(:placeholder-shown) ~ label, .form-floating > .form-control-plaintext ~ label, .form-floating > .form-select ~ label {
      -webkit-transform: scale(0.85) translateY(-80%) translateX(0rem);
      transform: scale(0.85) translateY(-80%) translateX(0rem);
      z-index: 20;
      color: #FFFFFF80;
      opacity: 1;
  }

  .texta.form-floating > .form-control:focus ~ label, .texta.form-floating > .form-control:not(:placeholder-shown) ~ label, .texta.form-floating > .form-control-plaintext ~ label, .texta.form-floating > .form-select ~ label {
      /*-webkit-transform: scale(0.85) translateY(-50%) translateX(0rem);
      transform: scale(0.85) translateY(-50%) translateX(0rem);*/
      height: calc(3.5rem + 2px);
      z-index: 20;
      color: #FFFFFF80;
      opacity: 1;
  }

  input:focus,
  input:active{
      outline: none !important;
      box-shadow: none !important;
  }

  #input-5{
    border-radius: 10px;
    font-size: 18px;
    font-weight: 500;
    font-family:"Bai Jamjuree", sans-serif;
    line-height: 22.5px;
  }


  .text img{
  max-width: 76.85px;
  max-height: 77.39px;
}


@media (max-width: 1440px){
  #contact-us .col-sm-7{
    width:70%;
  }

  .capabilities{
    margin-right: 10px;
    width: 270px;
  }
  #banner .col-sm-9{
    width: 85%
  }

  .direcciones{
    padding-left: 20px !important;
  }
}

@media (max-width: 1356px){
  #banner .col-sm-8{
    width: 78%;
  }

  .capabilities{
    margin-right: 10px;
    width: 250px;
  }

  #banner .col-sm-9{
    width: 85%
  }

  #banner .col-sm-6{
    width: 53%;
  }
}

@media (max-width: 1300px){
  #banner .col-sm-8{
    width: 90%;
  }

  .ps-5.margin{
    padding-left: 28px !important;
  }

  .capabilities{
    margin-right: 7px;
    width: 240px;
  }


  #banner .col-sm-9{
    width: 90%;
  }

  #banner .col-sm-6{
    width: 50%;
  }

}

@media (max-width: 1210px){

  .capabilities{
    margin-right: 5px;
    width: 230px;
    height: 260px;
  }

  #banner .col-sm-9{
    width: 90%;
  }

  #vector {
   
   top: 8%;
   right: 9%;
   width: 168px;
   height: 168px;
}

.ps-5.margin{
    padding-left: 20px !important;
  }

  #contact-us .text, #contact-us .text-contact{
    max-width: 78%;
  }

  #img-banner{
    width: 250px;
  }
}

@media (max-width: 1185px){

  #img-banner img{
    width: 300px;
  }

  #img-banner  {
    width: 50px;
 
}
  #banner h2, #contact-us h2{
    font-size: 50px;
    line-height:60px;
  }

    #banner h1{
    font-size: 70px;
    line-height: 90px;
  }

  #banner h3, #offer h3{
  font-size: 30px;
  line-height: 60px;
  }



  #banner p{
    font-size: 17px;
    line-height: 22px;
  }


  #banner .col-sm-9{
    width: 90%;
  }



.row.get-started{
  padding-bottom: 10%;
}

}

@media (max-width: 1090px){

  #img-banner img{
    width:270px;
  }


  #vector {
   
   top: 8%;
   right: 9%;
   width: 168px;
   height: 168px;
}

.ps-5.margin{
  padding-left: 40px !important;
}



}

@media (max-width: 1059px){
  #banner .col-sm-9{
    width: 100%;
  }



  .capabilities{
    margin-right: 5px;
    width: 220px;
    height: 280px;
  }

}

@media (max-width: 1010px){
  #banner-img{
    background-size: cover;
  }

  #banner h2{
    padding-bottom: 3%;
  }

  #img-banner img{
    width: 250px;
  }
  #contact-us .col-sm-7 {
    width: 80%;
}
  .capabilities{
    width: 290px;
    height: 240px;
    margin-right: 5px;
  }



 
}

@media (max-width:950px ){

  .col-md-3 {
  width: 30%;
}  #banner .col-sm-6{
    width: 60%;
  }
#vector {
   
   top: 8%;
   right: 8%;
   width: 108px;
   height: 108px;
}


#banner h1{
    font-size: 60px;
    line-height: 70px;
}

#img-banner img{
    width: 230px;
    margin-top: 72%;
  }



  .direcciones, .direcciones .ps-5{
    padding-left: 10px !important;
  }






}

@media (max-width:848px){

  .capabilities{
    margin-right: 0;
    width: 290px;
    height: 240px;
  }

  .direcciones, .direcciones .ps-5{
    padding-left: 10px !important;
  }

#img-banner img{
  width:200px;
}




  #banner h1{
    font-size: 60px;
    line-height: 80px
  }

  #contact-us .col-sm-7 {
    width: 90%;
}
form{
  padding-top: 2% !important;
}
#vector {
   
   top: 5%;
   right: 5%;
   width: 90px;
   height: 90px;
}
.col-md-3 {
  width: 30%;
}
}

@media (max-width: 850px){


  #banner .col-sm-8{
    width: 100%;
  }

  .direcciones{
    width: 20px;
  }

  .ps-5.margin{
    padding-left: 10px !important;
  }



.offer-wrapper.col-md-3{
  width: 30%;
}

.offersrow {
  justify-content: center;
}
#contact-us form .row{
  padding-bottom: 0;
}

form{
  padding-top: 10%;
}

.form-control{
  margin-bottom: 20px;
}

form .col{
  flex: auto;
}
#contact-us .text, #contact-us .text-contact{
 overflow: hidden;
}

.direcciones.col{
  flex:auto;
}

  .texta.form-floating > .form-control:focus ~ label, .texta.form-floating > .form-control:not(:placeholder-shown) ~ label, .texta.form-floating > .form-control-plaintext ~ label, .texta.form-floating > .form-select ~ label, .media.form-floating > .form-control:focus ~ label, .media.form-floating > .form-control:not(:placeholder-shown) ~ label, .media.form-floating > .form-control-plaintext ~ label, .media.form-floating > .form-select ~ label {
      height: calc(3.4rem + 2px);
  }
  #banner{
  padding-top: 10%;
}
  #contact-us .text-contact {
    flex-direction: column; 
  }

  #contact-us .direcciones {
    order: 2; 
    padding-top: 1rem; 
  }

  #contact-us form {
    order: 1; 
  }
  .direcciones .row{
    width: 500px;
  }

  .form-floating{
    margin-bottom: 10px;
  }

  .text-right {
    width: 250px;
    height: 62px;
}
  #contact-us .text-contact .col-sm-8 {
    width: 100%;
  }
}

@media (max-width: 750px){
#menu{
  padding-top: 5%;
}
 
.inside img {
    width: 246px;
}

.inside .centerv p{
    font-size: 20px;
    line-height: 25px;
}

.text-right {
    width: 250px;
    height: 62px;
}
 
#contact-us .col-sm-7 {
    width: 90%;
}
.inside.container-fluid{
  padding:0;
}
  #contact-us .col-sm-7 {
    width: 80%;
}
#banner h2{
  font-size: 40px;
  line-height: 50px;
}

#menu{
  padding-top: 7%;
}

#img-banner img{
   width: 180px;
}
#img-banner  {
    width: 50px;
    margin-top: 12%;
}
 
#banner-img{
    height: auto;
    background-size: cover;
    background-position: bottom;
}

#banner .get-started{
  padding-bottom: 10%;
}

#banner h1{
    font-size: 35px;
    line-height: 50px;
}


#banner h3, #offer h3 {
    font-size: 30px;
    line-height: 40px;
    padding-top: 10%;
    padding-bottom: 5%;
}

.col-sm-9 {
    max-width: 100%;
}
.highlight-img img{
  overflow: hidden;
}

.capabilities.col{
  flex:auto;
}

.capabilities{
  width: 80%;
  height: fit-content;
}

.offer-wrapper.col-md-3{
    width: 50%;
}

.especial{
  font-size: 21px !important;
}

.pt-5{
  padding-top: 5% !important;
}

#contact-us h2{
  font-size: 40px;
  line-height: 40px;
}

#vector{
    top:10%;
    right: 10%;
    width: 70px;
    height: 70px;
}

#contact-us form .row{
  padding-bottom: 0;
}

form{
  padding-top: 10%;
}

.form-control{
  margin-bottom: 20px;
}

form .col{
  flex: auto;
}
#contact-us .text, #contact-us .text-contact{
 overflow: hidden;
}

.direcciones.col{
  flex:auto;
}
#banner .col-sm-6 {
    width: 60%;
}
  .texta.form-floating > .form-control:focus ~ label, .texta.form-floating > .form-control:not(:placeholder-shown) ~ label, .texta.form-floating > .form-control-plaintext ~ label, .texta.form-floating > .form-select ~ label, .media.form-floating > .form-control:focus ~ label, .media.form-floating > .form-control:not(:placeholder-shown) ~ label, .media.form-floating > .form-control-plaintext ~ label, .media.form-floating > .form-select ~ label {
      height: calc(3.4rem + 2px);
  }

  #contact-us .text-contact {
    flex-direction: column; 
  }

  #contact-us .direcciones {
    order: 2; 
    padding-top: 1rem; 
  }

  #contact-us form {
    order: 1; 
  }
  .direcciones .row{
    width: 500px;
  }

  .form-floating{
    margin-bottom: 10px;
  }

}

@media (max-width:710px){
 
  #banner .text-wrapper, #banner .get-started, #banner .highlight-img, #offer .text, #menu .text-wrapper{
    max-width: 89%;
    margin: auto;
    overflow: hidden;
}

  
 
#contact-us .text, #contact-us .text-contact {
    max-width: 90%;
}
.paragraph .margin{
  width:100%;
  padding-left:0 !important;
  margin-left:0 !important;
}
#banner .col-sm-6 {
    width: 65%;
}
#vector {
   
   top: 13.5%;
   right: 10%;
   width: 50px;
   height: 50px;
}
.ps-5.margin {
    padding-left: 0px !important;
 
}
.paragraph{
  padding-bottom: 30px;
}
#contact-us h2 {
    font-size: 40px;
    line-height: 48px;
}


}

@media(max-width:690px){
  #banner .get-started{
    padding-top: 14%;
  }
  #img-banner img{
    display: none;
  }
  .h{
    display: none !important;
  }
  .inside img {
    width: 166px;
}

.inside .centerv p{
    font-size: 14px;
    line-height: 20px;
}
#banner  {
    padding-top: 18%;
}
.text-right {
    width: 115px;
    height: 42px;
}

  .form-floating.name{
    margin-top: 20px;
  }

  .direcciones, .direcciones .ps-5{
    padding-left: 10px !important;
  }
}

</style>
