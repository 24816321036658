<template>
  <div id="app">
    <router-view/>
  </div>
</template>


<script>

export default {
  name: 'app',
  components: {
  }
}
</script>

<style>
#app, * {
  font-family:  'Michroma', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #041F30;
  text-decoration: NONE;
}



</style>