<template>
        <div>
                <div id="banner-img">
                <!-- MENU -->
                <b-container id="menu" fluid>
                    <div class="m-0">
                      <div class="d-flex align-items-center">
                          <b-container class="inside" fluid>
                              <b-row class="justify-content-between align-items-center text-wrapper">
                                  <b-col class="col-sm-6" >
                                    <a :class="{'home': 'home' == this.$route.name}" :href="$router.resolve({ name: 'home'}).href"> <img src="../assets/img/Logo White.svg"></a>
                                  </b-col>
                                  <b-col class="col-sm-6 justify-content-end" >
                                    <a :class="{'home': 'home2' == this.$route.name}" :href="$router.resolve({ name: 'home2'}).href">
                                                <div class="centerv text-right">
                                        <a :class="{'home': 'home2' == this.$route.name}" :href="$router.resolve({ name: 'home2'}).href"><p class="my-0">CONTACT US</p></a>
                                        </div></a>
                                  </b-col>
                              </b-row>
                          </b-container>
                      </div>
                    </div>
                  </b-container>
                </div>
        
                <div class="privacy">
                        <div v-html="privacyPolicyHtml"></div>
                </div>
        </div>
</template>

<script>
export default {
  data() {
    return {
      privacyPolicyHtml: '',
    };
  },
  mounted() {
    this.loadPrivacyPolicy();
    if (this.$route.name === 'privacy-policy' && window.performance) {
      if (performance.navigation.type === 1) {
        this.$router.push({ name: 'home' });
      }
    }
  },
  methods: {
    loadPrivacyPolicy() {
      fetch('/html/policy.html')
        .then(response => {
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          return response.text();
        })
        .then(html => {
          this.privacyPolicyHtml = html;
        })
        .catch(error => {
          console.error('Error fetching privacy policy:', error);
        });
    },
  },
};
</script>



<style scoped>
/* MENU */

*{
  text-decoration: none;
}
#menu{
  max-width: 100%;
  padding-top: 2%;
  padding-bottom: 2%;
  width: 100%;
}
.inside .centerv p{
  font-size: 20px;
  line-height: 25px;
  font-weight: 400;
  color:#0b0a46;
  font-family:"Bai Jamjuree", sans-serif;;
}

#menu .row, #menu .col{
  display: flex;
}

#banner-img .text-wrapper{
  max-width: 83%;
  margin: auto;
}
.text-right{
    text-align: center;
    font-family:"Bai Jamjuree", sans-serif;;
    display: flex;
    justify-content: center;
    border: 1px solid #fff;
    border-radius: 100px;
    width:277px;
    height: 72px;
    align-items: center;
    background-color: #FFFFFF;
}
.inside img{
  width: 306px;
}

#banner-img{
  background-image: url("../assets/img/img_banner_background.png");
  height: auto;
  background-repeat: no-repeat;
  background-size: cover;
}

.privacy{
        max-width: 60%;
        padding-top: 5%;
        padding-bottom: 5%;
        margin: auto;
        font-family:  'Michroma', sans-serif !important;

}


@media (max-width: 750px){

        .privacy{
                max-width: 80%;
        padding-top: 5%;
        padding-bottom: 5%;
        }
        .inside img {
    width: 166px;
}

.inside .centerv p{
    font-size: 14px;
    line-height: 20px;
}

.text-right {
    width: 115px;
    height: 42px;
}

#menu .text-wrapper{
  max-width: 89%;
  overflow: hidden;
  margin: auto;
}
#menu{
  padding-bottom:4%;
}
.container-fluid{
  padding-left: 0 !important;
  padding-right: 0 !important;
}
.inside.container-fluid{
padding:0;
}

#banner h2{
font-size: 40px;
line-height: 50px;
}

#menu{
padding-top: 7%;
}

#banner-img{
height: auto;
  background-size: cover;
  background-position: bottom;
}

}

</style>