import Vue from 'vue'
import Router from 'vue-router'
import HomeComponent from '../components/HomeComponent'
import PrivacyPolicy from '../components/PrivacyPolicy'
//import About from '../components/About'


Vue.use(Router)


export default new Router({
  mode: 'history',
  routes: [
    {
        path: '/',
        name: 'home',
        component: HomeComponent
    },
    {
      path: '/privacypolicy',
      name: 'privacypolicy',
      component: PrivacyPolicy
    },
    {
        path: '/#contact-us',
        name: 'home2',
        component: HomeComponent
      }
  ]
})

